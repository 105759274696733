<template>
  <div>
  <v-col cols="12" md="12">
    <v-row>
      <v-col cols="12" md="12">
        <v-card-title>
          <div>
            <v-breadcrumbs :items="links" />
          </div>
          <v-spacer />
          <div
            class="text-h3 text-center font-weight-medium"
            style="color: #228b22"
          >
            Comprehensive Insurance
          </div>
          <v-spacer />
          <v-spacer />
        </v-card-title>
      </v-col>
    </v-row>
    <v-container class="rounded">
      <v-form
        v-model="valid"
        class="rounded pa-3"
        @submit.prevent="CreateQuote"
      >
        <v-row class="mt-2">
          <v-col cols="4" md="3">
            <v-text-field
              v-model="vrn"
              outlined
              label="VRN"
              class="purple-input my-input"
              autocomplete="on"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="4" md="3">
            <v-text-field
              v-model="client_name"
              outlined
              label="Client Name"
              class="purple-input my-input"
              autocomplete="on"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="4" md="3">
            <v-text-field
              v-model="make_model"
              outlined
              label="Make/Model"
              class="purple-input my-input"
              autocomplete="on"
              :rules="[rules.required]"
            />
          </v-col>
<!--          <v-col cols="4" md="3">-->
<!--            <v-select-->
<!--              v-model="vehicle_use"-->
<!--              :items="vrn_use"-->
<!--              item-text="name"-->
<!--              item-value="value"-->
<!--              label="Vehicle Use"-->
<!--              outlined-->
<!--              :rules="[rules.required]"-->
<!--              style="text-transform: uppercase"-->
<!--              @change="ChangeVrnUse"-->
<!--            />-->
<!--          </v-col>-->
          <v-col cols="4" md="3">
            <v-select
              v-model="cover_period"
              :items="covers"
              item-text="name"
              item-value="value"
              label="Cover Period"
              outlined
              :rules="[rules.required]"
              style="text-transform: uppercase"
            />
          </v-col>
          <v-col cols="4" md="3">
            <v-text-field
              v-model="client_phonenumber"
              outlined
              label="Phone No."
              class="purple-input my-input"
              autocomplete="on"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="4" md="3">
            <v-text-field
              v-model="client_email"
              outlined
              hide-details="auto"
              type="email"
              label="Client Email"
              class="purple-input my-input"
              autocomplete="on"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="4" md="3">
            <v-text-field
              v-model="delivery_address"
              outlined
              label="Address"
              class="purple-input my-input"
              autocomplete="on"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="4" md="3">
            <v-text-field
              v-model.number="sum_insured"
              outlined
              label="Sum insured"
              class="purple-input my-input"
              autocomplete="off"
              :rules="[rules.required, rules.numeric]"
            />
          </v-col>
          <v-col cols="4" md="3">
            <v-text-field
              v-model="rate_insured"
              label="Rate"
              type="number"
              step="0.001"
              min="0.01"
              max="5.00"
              ref="input"
              :rules="[rules.required, rules.numeric_rate]"
              v-model.number="rate_insured"
            ></v-text-field>
          </v-col>
          <v-col v-if="true" cols="4" md="3">
            <template>
              <v-dialog
                ref="dialog"
                v-model="modal1"

                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-combobox
                    v-model="PolicyStart_date"
                    label="Select Policy Start Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="PolicyStart_date">
                  <v-btn text color="primary" @click="modal1 = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="modal1 = false">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </template>
          </v-col>
          <v-col cols="4" md="3">
            <v-text-field
              v-model="PolicyEnd_date"
              outlined
              label="Policy End Date"
              readonly
              class="purple-input my-input"
              autocomplete="off"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12" class="text-center mb-1">
<!--            <v-btn-->
<!--              color="success"-->
<!--              :disabled="!valid"-->
<!--              @click="CreateQuote"-->
<!--            >-->
<!--              Generate-->
<!--            </v-btn>-->
            <v-btn
              color="info"
              :disabled="!valid"
              @click="SaveQuote"
            >
              Save
            </v-btn>
            <v-btn
              color="purple"
              :disabled="!valid"
              @click="dialog = true"
            >
              Process
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>




<!--    THIS IS WHERE THE DIALOG FOR PROCESSING STARTS-->
    <v-dialog
      v-model="dialog"

    >
      <v-card class="text-center">
        <v-card-title>

          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="dialog = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <!--the newv table with options-->

        <!--          the new table ends here-->
        <v-row>
          <v-col

          >
            <base-material-card>


              <v-form>
                <v-container class="py-0" md="3">
                  <v-row>
                    <v-col cols="4" md="3">
                      <v-text-field
                        class="purple-input"
                        label="VRN"
                        v-model="vrn"
                        disabled
                      />
                    </v-col>

                    <v-col cols="4" md="3">
                      <v-text-field
                        class="purple-input"
                        label="MAKE/MODEL"
                        v-model="make_model"
                        disabled
                      />
                    </v-col>

                    <v-col cols="4" md="3">
                      <v-text-field
                        label="PERIOD"
                        class="purple-input"
                        v-model="cover_period"
                        disabled
                      />
                    </v-col>

                    <v-col
                      cols="4" md="3">
                      <v-text-field
                        label="VEHICLE VALUE"
                        v-model.number="sum_insured"
                        class="purple-input"
                        disabled
                      />
                    </v-col>

                    <v-col cols="4" md="3">
                      <v-text-field
                        label="RATE INSURED"
                        v-model="rate_insured"
                        class="purple-input"
                        disabled
                      />
                    </v-col>

                    <v-col cols="4" md="3">
                      <v-text-field
                        label="Client Name"
                        v-model="client_name"
                        class="purple-input"
                        disabled
                      />
                    </v-col>

                    <v-col cols="4" md="3">
                      <v-select
                        v-model="company_type"
                        :items="co_type"
                        item-text="name"
                        item-value="value"
                        label="Insurance Company"
                        outlined
                        :rules="[rules.required]"
                        style="text-transform: uppercase"
                        @change="ChangeVrnType"
                      />
                    </v-col>
                    <v-col cols="4" md="3">
                      <v-text-field
                        v-model="client_phonenumber"
                        outlined
                        label="Client Number"
                        class="purple-input my-input"
                        autocomplete="on"
                        :rules="[rules.required]"
                        disabled
                      />
                    </v-col>
                    <v-col cols="4" md="3">
                      <v-text-field
                        v-model="client_idnumber"
                        outlined
                        label="Client ID"
                        class="purple-input my-input"
                        autocomplete="on"
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col cols="4" md="3">
                      <v-text-field
                        v-model="client_email"
                        outlined
                        hide-details="auto"
                        type="email"
                        label="Client Email"
                        class="purple-input my-input"
                        autocomplete="on"
                        :rules="[rules.required]"
                        disabled
                      />
                    </v-col>
                    <v-col cols="4" md="3">
                      <v-text-field
                        v-model="lead_source"
                        outlined
                        label="Lead Source"
                        class="purple-input my-input"
                        autocomplete="on"
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col cols="4" md="3">
                      <v-select
                        v-model="currency"
                        :items="currency_option"
                        item-text="name"
                        item-value="value"
                        label="Payment Method"
                        outlined
                        :rules="[rules.required]"
                        style="text-transform: uppercase"

                      />
                    </v-col>
                    <v-col cols="4" md="3">
                      <v-text-field
                        v-model="rrn"
                        outlined
                        label="REFERENCE No."
                        class="purple-input my-input"
                        autocomplete="off"
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col cols="4" md="3">
                      <v-text-field
                        v-model="amount_paid"
                        label="PAID AMOUNT"
                        type="number"
                        step="1"
                        min="1"
                        max="10000"
                        ref="input"
                        :rules="[rules.required]"
                        v-model.number="amount_paid"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4" md="3">
                      <v-text-field
                        label="AMOUNT DUE"
                        :value="result2Formatted"
                        @input="updateResult2"
                        class="purple-input"
                        disabled
                      />
                    </v-col>
                    <v-col cols="4" md="3">
                      <v-select
                        v-model="clienttype"
                        :items="client_type"
                        item-text="name"
                        item-value="value"
                        label="Client Type"
                        outlined
                        :rules="[rules.required]"
                        style="text-transform: uppercase"

                      />
                    </v-col>
                    <v-col cols="4" md="3">
                      <v-select
                        v-model="selected_gender"
                        :items="gender"
                        item-text="name"
                        item-value="value"
                        label="Gender Type"
                        outlined
                        :rules="[rules.required]"
                        style="text-transform: uppercase"

                      />
                    </v-col>
                    <v-col cols="4" md="3">
                      <v-text-field
                        label="Policy Starts"
                        v-model="PolicyStart_date"
                        class="purple-input"
                        disabled
                      />
                    </v-col>
                    <v-col cols="4" md="3">
                      <v-text-field
                        label="Policy End"
                        v-model="PolicyEnd_date"
                        class="purple-input"
                        disabled
                      />
                    </v-col>
                    <v-col cols="4" md="3">
                      <v-text-field
                        label="Client Address"
                        v-model="delivery_address"
                        class="purple-input"
                        disabled
                      />
                    </v-col>
                    <v-col v-if="true" cols="4" md="3">
                      <template>
                        <v-dialog
                          ref="dialog"
                          v-model="modal1"
                          :return-value.sync="date"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-combobox
                              v-model="dob"
                              label="Select D.O.B"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            />
                          </template>
                          <v-date-picker v-model="dob">
                            <v-btn text color="primary" @click="modal1 = false">
                              Cancel
                            </v-btn>
                            <v-btn text color="primary" @click="modal1 = false">
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </template>
                    </v-col>
                    <v-col cols="6" md="6">
                      <v-text-field
                        v-model="notes"
                        outlined
                        label="NOTES"
                        class="purple-input my-input"
                        autocomplete="on"
                        :rules="[rules.required]"
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      class="text-right"
                    >
                      <v-btn
                        color="blue"
                        class="mr-0"
                        :disabled="!valid"
                        @click="ProcessQuote"
                      >
                        Proceed
                      </v-btn>
                      <div class="text-center">
                        <v-overlay :value="overlay">
                          <v-progress-circular
                            indeterminate
                            size="64"
                          />
                        </v-overlay>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </base-material-card>
          </v-col>

        </v-row>


        <v-card-actions>
          <v-spacer />

          <v-btn
            color="error"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

<!--    THIS IS WHERE THE DIALOG FOR PROCESSING ENDS-->

    <base-material-card
      color="info"
      icon="mdi-clipboard-text"
      title="VERNIA Response"
      class="px-5 py-3"
    >

      <v-simple-table>
        <thead>
        <tr>
          <th class="primary--text" :rules="[rules.required]">
            VRN
          </th>
          <th class="primary--text">
            Client Name
          </th>
          <th class="primary--text">
            COVER TYPE
          </th>
          <th class="primary--text font-weight-black">
            AMOUNT DUE
          </th>
          <th class="primary--text">
            Duration
          </th>
          <th class="primary--text">
            Policy Starts & End
          </th>
        </tr>
        </thead>

        <tbody>
        <tr>
          <td>{{ vrn }}</td>
          <td>{{ client_name }}</td>
          <td><v-btn small color="info">Comprehensive</v-btn></td>
          <td class="font-weight-black" :precision="2"><v-btn small color="green">{{ result2 }}</v-btn></td>
          <td><v-btn small color="green">DEFAULT</v-btn></td>
          <td><v-btn small color="info">{{cover_period}} Months</v-btn></td>
        </tr>
        <tr>
          <td>{{ vrn }}</td>
          <td>{{ client_name }}</td>
          <td>Comprehensive</td>
          <td class="font-weight-black">{{ result }}</td>
          <td>Annual</td>
          <td>12 Months</td>
        </tr>
        <tr>
          <td>{{ vrn }}</td>
          <td>{{ client_name }}</td>
          <td>Comprehensive</td>
          <td class="font-weight-black">{{ result1 }}</td>
          <td>2 Terms</td>
          <td>8 Months</td>
        </tr>
        <tr>
          <td>{{ vrn }}</td>
          <td>{{ client_name }}</td>
          <td>Comprehensive</td>
          <td class="font-weight-black" :precision="2">{{ result3 }}</td>
          <td>Term</td>
          <td>4 Months</td>
        </tr>

        </tbody>
      </v-simple-table>
    </base-material-card>
  </v-col>
  <div class="text-center">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
  </div>
  </div>

</template>
<script>
// import { mapState } from 'vuex'
import axios from "axios";
// import { API } from "../../../api";
const moment = require("moment");
export default {
  name: "Comprehensive",
  data() {
    return {
      dialog: false,
      amount_paid:"",
      currency: "",
      currency_option: [
        { name: "USD CASH", value: "USD" },
        { name: "FCA", value: "FCA" },
      ],
      clienttype:"",
      client_type:[
        { name: "HVC", value: "HVC" },
        { name: "GVC", value: "GVC" },
        { name: "VC", value: "VC" },
      ],
      referalState: false,
      proceed_dialog: false,

      companyState: [
        { name: "Yes", value: true },
        { name: "No", value: false },
      ],
      business_source: "",
      companyName: "",
      companyID: "",
      isCompany: false,
      referalName: "",
      rrn: "",
      dobmodal: false,
      delivery_address: "",
      policy_number: "",
      client_town: "",
      policy_number_icecash: "",
      vehicle_type_comprehensive: "",
      vehicle_type_icecash: "",
      vehicle_use_comprehensive: "",
      vehicle_use_icecash: "",
      sum_insured: 0,
      rate_insured: 0.04,
      PolicyStart_date: null,
      PolicyEnd_date: null,
      date: new Date().toISOString().substring(0,10),
      modal1: false,
      modal: false,
      iceCashPatnerRef: "",
      DurationStamp_duty: "-----",
      DurationGvt_levy: "------",
      DurationCover_amount: "------",
      DurationPremium_amount: "------",
      QouteResult: 0,
      delivery_option: "",

      delivery_town_object: "",
      delivery_method: "",
      delivery_methods: [
        { name: "Postal", value: 1 },
        { name: "Office Collection", value: 2 },
        { name: "Delivery", value: 3 },
      ],
      selected_package: "",
      packages: [
        { name: "Standard", value: 1 },
        { name: "Premium", value: 2 },
        { name: "Ultra Premium", value: 3 },
      ],
      package: "",
      gender: [
        { name: "Male", value: "Male" },
        { name: "Female", value: "Female" },
      ],
      selected_gender: "",
      age: "",
      birth_date: "",
      dob:"",
      notes:"",
      viewTransactionDetails: false,
      Address2: "",
      zone: "",
      insurance_type: "",
      duration: "",
      links: [
        {
          text: "Insurance - Comprehensive",
          disabled: false,
          href: "",
        },
        {
          text: "Generate Quote",
          disabled: true,
          href: "",
        },
      ],
      viewTransaction: false,
      policy_start_date: "",
      policy_end_date: "",
      overlay: false,
      overlay1:false,
      trans_status: "",
      client_contactnumber: "",
      insurance_id: "",
      Address1: "",
      delivery_town: "",
      EcocashWait: 0,
      retrieve_policyDialog: false,
      payment_methods: [
        { name: "Ecocash", value: 3 },
        { name: "Cash", value: 1 },
        { name: "Point of Sale", value: 2 },
      ],
      payment_method: "",
      client_idnumber: "",
      lead_source:"",
      show_quote: false,
      // date: new Date(
      //   Date.now() - new Date().getTimezoneOffset() * 60000
      // ).toISOString(),
      valid: false,
      paymentForm_valid: false,
      trans_summary: false,
      headers: [
        {
          sortable: false,
          text: "ID NUMBER",
          value: "id_number",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "VRN",
          value: "vrn",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "FIRSTNAME",
          value: "first_name",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "LASTNAME",
          value: "surname",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "POLICY START",
          value: "policy_start_date",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "POLICY END",
          value: "policy_end_date",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "POLICY STATUS",
          value: "policy_status",
          class: "primary--text title",
          width: "10%",
        },
        {
          sortable: true,
          text: "DELIVERY STATUS",
          value: "delivery_status",
          class: "primary--text title",
          width: '10%'
        },
        {
          sortable: true,
          text: "RECEIPTING STATUS",
          value: "receipting_status",
          class: "primary--text title",
          width: '10%'
        },
        {
          sortable: true,
          text: "PRINTING STATUS",
          value: "_status",
          class: "primary--text title",
          width: '10%'
        },

        {
          sortable: true,
          text: "VIEW",
          value: "view",
          class: "primary--text title",
        },
      ],
      company_type: "",
      co_type: [
        {name: "MOOVAH", value: "MOOVAH"},
        {name: "FBC Insurance", value: "FBC Insurance"},
        {name: "NICOZ", value: "NICOZ"},
      ],
      vehicle_type: "",
      vrn_type: [
        { name: "PRIVATE CAR", value: "Private Car" },
        { name: "COMMERCIAL VEHICLE", value: "Commercial Vehicle" },
        { name: "COMMERCIAL TRAILERS", value: "Commercial Trailers" },
        { name: "COMMUTER OMNIBUS", value: "Commuter Omnibus" },
        { name: "SCHOOL BUS", value: "School Bus" },
        { name: "STAFF BUS", value: "Staff Bus" },
        { name: "TRACTORS/COMBINE HARVESTERS", value: "Tractors/Combine Harvesters" },
        { name: "AMBULANCE/HEARSE/FIRE ENGINES", value: "Ambulance/Hearse/Fire Engines" },
        { name: "AGRICULTURAL IMPLEMENTS", value: "Agricultural Implements" },
      ],
      vehicle_use: "",
      vehicle_make: "null",
      vehicle_model: "",
      make_model:"",
      yearOfmanufacture: "null",
      tax_class: "",
      vrn_use: [],
      qoutes: [],
      covers: [
        { name: "Term", value: 4 },
        { name: "Two Terms", value: 8 },
        { name: "Annual", value: 12 },
        { name: "5 Months", value: 5 },
        { name: "6 Months", value: 6 },
        { name: "7 Months", value: 7 },
        { name: "9 Months", value: 9 },
        { name: "10 Months", value: 10 },
        { name: "11 Months", value: 11 },
      ],
      add: false,
      vrn: "",
      stamp_duty: "---",
      gvt_levy: "---",
      cover_amount: "---",
      premium_amount: "---",
      pay_dialog: false,
      cover_period: "",
      quote_message: "",
      quote_type: "",
      search: "",
      client_name: "",
      client_surname: "",
      client_email: "",
      client_phonenumber: "",
      paid_amount: "",
      cover_period_approved: "",
      approved_policy_message: "",
      approved_trans_status: "",
      approved_Vehiclemake: "",
      approved_Vehiclemodel: "",
      approved_Vehicleyear: "",
      approved_Vehiclevrn: "",
      approved_by: "",
      approved_date: "",
      rules: {
        required: (value) => !!value || "Required.",
        numeric: (value) => {
          const pattern = /[0-9]/;
          return pattern.test(value) || "Invalid Amount Format";
        },
        numeric_rate: (value) => {
          if(value > 5) return 'Error Rate Range'
          return true
        },
        counter: (value) => value.length >= 10 || "Max 10 characters",
        counter_id: (value) => value.length >= 11 || "Max 11 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  computed: {
    result() {
      const calculation = (this.sum_insured * this.rate_insured)+((this.sum_insured * this.rate_insured) * 0.05)+ 10.8;
      return parseFloat(calculation.toFixed(2));
    },
    result1() {
      const calculation = ((this.sum_insured * this.rate_insured)+((this.sum_insured * this.rate_insured) * 0.05)+ 10.8)* 0.6666667;
      return parseFloat(calculation.toFixed(2));
    },
    result2() {
       const calculation = ((this.sum_insured * this.rate_insured) + ((this.sum_insured * this.rate_insured) * 0.05) + 10.8) * (this.cover_period/12);
      return parseFloat(calculation.toFixed(2));
    },
    result3() {
      const calculation = ((this.sum_insured * this.rate_insured)+((this.sum_insured * this.rate_insured) * 0.05)+ 10.8)/ 3;
      return parseFloat(calculation.toFixed(2));
    },
    result2Formatted() {
      return parseFloat(this.result2).toFixed(2);
    }
  },
  watch: {
    PolicyStart_date (newDate) {
      if (this.cover_period) {
        this.updatePolicyEnd_date();
      }
    },
    cover_period (newPeriod) {
      if (this.PolicyStart_date) {
        this.updatePolicyEnd_date();
      }
    },
    EcocashWait: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.EcocashWait--;
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  created() {
    this.GetTransactions();
  },

  methods: {
    updatePolicyEnd_date () {
      let startDate = new Date(this.PolicyStart_date);
      startDate.setMonth(startDate.getMonth() + Number(this.cover_period));
      this.PolicyEnd_date = startDate.toISOString().substring(0,10);
    },
    CalculateAge() {
      this.age = moment().diff(this.dob, "years");
    },
    async ChangeDelivery() {
      if (this.delivery_option === 1 || this.delivery_option === 3) {
        this.delivery_method = 1;
      } else if (this.delivery_option === 3) {
        this.delivery_method = 3;
      }
      console.log(this.delivery_method);
    },
    async view() {
      let info = [];
      info = this.delivery_town_object.split(",");
      this.delivery_town = info[0];
      this.zone = info[1];
    },
    async TransactionInfo(val) {
      // global date
      const date = new Date();

      this.policy_number = val.policy_number;
      this.vrn = val.vrn;
      this.client_idnumber = val.id_number;
      this.client_name = val.first_name;
      this.client_surname = val.surname;
      this.selected_gender = val.client_sex;
      this.age = val.client_sex;
      this.client_phonenumber = val.client_mobile;
      this.client_email = val.client_email;
      this.birth_date = val.birth_date;
      this.Address1 = val.address1;
      this.Address2 = val.address2;
      this.delivery_town = val.town;
      this.zone = val.zone;
      this.cover_period_approved = val.policy_duration;
      // ! generaeted date
      this.policy_start_date = Date();
      this.policy_end_date = val.policy_end_date;
      this.premium_amount = val.premium;
      this.stamp_duty = val.stamp_duty;
      this.gvt_levy = val.government_levy;
      this.insurance_type = val.insurance_type;
      this.vehicle_make = val.vehicle_make;
      this.vehicle_model = val.vehicle_model;
      this.vehicle_type = val.vehicle_type;
      this.yearOfmanufacture = val.vehicle_year;
      this.invoice_file = val.invoice_file;
      this.receipt_file = val.receipt_file;
      this.policy_schedule = val.policy_schedule_file;
      this.viewTransactionDetails = true;
      console.log(val);
    },
    ChangeVrnType() {
      let info = [];
      info = this.vehicle_type.split(",");
      this.vehicle_type_comprehensive = parseInt(info[0]);
      this.vehicle_type_icecash = parseInt(info[1]);
      if (this.vehicle_type_comprehensive === 1) {
        this.vrn_use = [
          { name: "OWN USE", value: "1,1" },
          { name: "BUSINESS USE", value: "2,25" },
          { name: "FLEET", value: "3,9" },
          { name: "PRIVATE HIRE", value: "4,13" },
          { name: "DRIVING SCHOOL", value: "5,60" },
        ];
        this.packages = [
          { name: "Standard", value: 1 },
          // { name: "Premium", value: 2 },
          // { name: "Ultra Premium", value: 3 },
        ];
      } else if (this.vehicle_type_comprehensive === 2) {
        this.vrn_use = [
          { name: "OWN USE", value: "1,1" },
          // { name: "FLEET", value: "3,9" },
          { name: "HIRE AND REWARD", value: "9,13" },
        ];
        this.packages = [
          { name: "Standard", value: 1 },
          // { name: "Premium", value: 2 },
          // { name: "Ultra Premium", value: 3 },
        ];
      } else if (this.vehicle_type_comprehensive === 3) {
        this.vrn_use = [
          { name: "OWN USE", value: "1,1" },
          { name: "BUSINESS USE", value: "2,25" },
          { name: "HIRE AND REWARD", value: "9,13" },
        ];
        this.packages = [{ name: "Standard", value: 1 }];
      } else if (this.vehicle_type_comprehensive === 4) {
        this.vrn_use = [
          { name: "OMNIBUS/UPTO 30 SEATS", value: "10,81" },
          { name: "OMNIBUS/31-60 SEATS", value: "11,84" },
          { name: "OMNIBUS/MORE THAN 60 SEATS", value: "12,87" },
        ];
        this.packages = [
          { name: "Standard", value: 1 },
          // { name: "Premium", value: 2 },
        ];
      } else if (this.vehicle_type_comprehensive === 5) {
        this.vrn_use = [
          { name: "SCHOOL BUS/UPTO 30 SEATS", value: "13,81" },
          { name: "SCHOOL BUS/31-60 SEATS", value: "14,84" },
          { name: "SCHOOL BUS/MORE THAN 60 SEATS", value: "15,87" },
        ];
        this.packages = [
          { name: "Standard", value: 1 },
          // { name: "Premium", value: 2 },
          // { name: "Ultra Premium", value: 3 },
        ];
      } else if (this.vehicle_type_comprehensive === 6) {
        this.vrn_use = [
          { name: "STAFF BUS/UPTO 30 SEATS", value: "16,81" },
          { name: "STAFF BUS/31-60 SEATS", value: "17,84" },
          { name: "STAFF BUS/MORE THAN 60 SEATS", value: "18,87" },
        ];
        this.packages = [
          { name: "Standard", value: 1 },
          // { name: "Premium", value: 2 },
          // { name: "Ultra Premium", value: 3 },
        ];
      } else if (this.vehicle_type_comprehensive === 11) {
        this.vrn_use = [
          { name: "OWN USE", value: "1,1" },
          { name: "PUBLIC HIRE", value: "8,29" },
        ];
        this.packages = [
          { name: "Standard", value: 1 },
          // { name: "Premium", value: 2 },
          // { name: "Ultra Premium", value: 3 },
        ];
      } else if (this.vehicle_type_comprehensive === 12) {
        this.vrn_use = [{ name: "HIRE AND REWARD", value: "9,13" }];
        this.packages = [
          { name: "Standard", value: 1 },
          { name: "Premium", value: 2 },
        ];
      } else if (this.vehicle_type_comprehensive === 13) {
        this.vrn_use = [
          { name: "OWN USE", value: "1,1" },
          { name: "BUSINESS USE", value: "2,25" },
          { name: "FLEET", value: "3,9" },
          { name: "PRIVATE HIRE", value: "4,13" },
          { name: "DRIVING SCHOOL", value: "5,60" },
          { name: "TRAILERS", value: "6,59" },
          { name: "CARAVANS", value: "7,59" },
          { name: "PUBLIC HIRE", value: "8,29" },
          { name: "HIRE AND REWARD", value: "9,13" },
          { name: "OMNIBUS/UPTO 30 SEATS", value: "10,81" },
          { name: "OMNIBUS/31-60 SEATS", value: "11,84" },
          { name: "OMNIBUS/MORE THAN 60 SEATS", value: "12,87" },
          { name: "SCHOOL BUS/UPTO 30 SEATS", value: "13,81" },
          { name: "SCHOOL BUS/31-60 SEATS", value: "14,84" },
          { name: "SCHOOL BUS/MORE THAN 60 SEATS", value: "15,87" },
          { name: "STAFF BUS/UPTO 30 SEATS", value: "16,81" },
          { name: "STAFF BUS/31-60 SEATS", value: "17,84" },
          { name: "STAFF BUS/MORE THAN 60 SEATS", value: "18,87" },
          { name: "CONTRACTORS PLANT AND HIRE", value: "19,59" },
          { name: "IMPLEMENTS", value: "20,59" },
        ];
      } else if (this.vehicle_type_comprehensive === 14) {
        this.vrn_use = [{ name: "IMPLEMENTS", value: "20,59" }];
        this.packages = [
          { name: "Standard", value: 1 },
          // { name: "Premium", value: 2 },
          // { name: "Ultra Premium", value: 3 },
        ];
      }
      console.log("Vehicle Uses", this.vehicle_type_comprehensive);
      console.log("Vehicle Uses", this.vrn_use);
    },

    updateResult2(event) {
      this.result2 = this.roundOff(event.target.value);
    },
    roundOff(value) {
      if (isNaN(value)) {
        return 0.00;
      }
      const result = Math.round(value * 100) / 100;
      return result.toFixed(2);
    },

    async ChangeVrnUse() {
      let info = [];
      info = this.vehicle_use.split(",");
      this.vehicle_use_comprehensive = parseInt(info[0]);
      this.vehicle_use_icecash = parseInt(info[1]);
      if (
        this.vehicle_use_comprehensive === 11 &&
        this.vehicle_type_comprehensive === 4
      ) {
        this.packages = [{ name: "Standard", value: 1 }];
      } else if (
        this.vehicle_use_comprehensive === 12 &&
        this.vehicle_type_comprehensive === 4
      ) {
        this.packages = [{ name: "Standard", value: 1 }];
      } else if (this.vehicle_type_comprehensive === 4) {
        this.packages = [
          { name: "Standard", value: 1 },
          { name: "Premium", value: 2 },
        ];
      }
    },
    async CreateQuote() {
      this.overlay = true;
      this.QouteResult = 0;
      this.insurance_type = "";
      this.DurationStamp_duty = "-----";
      this.DurationGvt_levy = "------";
      this.DurationCover_amount = "------";
      this.DurationPremium_amount = "------";
      this.stamp_duty = "---";
      this.gvt_levy = "---";
      this.cover_amount = "---";
      this.premium_amount = "---";
      this.vehicle_make = "null";
      this.vehicle_model = "null";
      this.yearOfmanufacture = "null";
      await new Promise((resolve) => setTimeout(resolve, 2000));
      // try{
      const JWTToken = this.$store.getters.accessToken;
      axios
        .get(
          API +
            "/insurance/comprehensive/?suminsured=" +
            this.sum_insured +
            "&vrn=" +
            this.vrn +
            "&product=" +
            this.selected_package +
            "&vrn_type=" +
            this.vehicle_type_comprehensive +
            "&vrn_usage=" +
            this.vehicle_use_comprehensive +
            "&duration_months=" +
            this.cover_period +
            "&vrn_type_icecash=" +
            this.vehicle_type_icecash +
            "&vrn_usage_icecash=" +
            this.vehicle_use_icecash +
            "",
          { headers: { Authorization: `Bearer ${JWTToken}` } }
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data);
            this.responsedata = true;
            this.show_quote = true;
            this.overlay = false;
            // response data
            this.insurance_type = res.data.Quotes[0].AnnualPolicy.InsuranceType;
            this.SumInsured = res.data.Quotes[0].AnnualPolicy.SumInsured;
            this.stamp_duty = res.data.Quotes[0].AnnualPolicy.StampDuty;
            this.gvt_levy = res.data.Quotes[0].AnnualPolicy.GovernmentLevy;
            this.cover_amount = res.data.Quotes[0].AnnualPolicy.CoverAmount;
            this.premium_amount = res.data.Quotes[0].AnnualPolicy.PremiumAmount;
            this.DurationStamp_duty =
              res.data.Quotes[0].DurationPolicy.StampDuty;
            this.DurationGvt_levy =
              res.data.Quotes[0].DurationPolicy.GovernmentLevy;
            this.DurationCover_amount =
              res.data.Quotes[0].DurationPolicy.CoverAmount;
            this.DurationPremium_amount =
              res.data.Quotes[0].DurationPolicy.PremiumAmount;
            this.vehicle_make = res.data.Quotes[0].Vehicle.Make;
            this.vehicle_model = res.data.Quotes[0].Vehicle.Model;
            this.insurance_id = res.data.Quotes[0].RTAPolicy.InsuranceID;
            this.quote_message = res.message;
            console.log(this.QouteResult);
          } else if (res.status === 202) {
            this.responsedata = true;
            this.overlay = false;
            this.quote_message = res.data.message.Response.Quotes[0].Message;
          } else if (res.status === 401) {
            this.overlay = false;
            window.clearTimeout(this.timeoutID);
            this.$store.commit("clearUserData");
            alert("Your Session has ended");
            // this.$router.push("/login");
            window.location.href = "/login";
          } else {
            this.overlay = false;
            window.clearTimeout(this.timeoutID);
            this.$store.commit("clearUserData");
            alert("Your Session has ended");
            // this.$router.push("/login");
            window.location.href = "/login";
          }
        });
    },




    async SaveQuote(){
      this.overlay = true;
      const payload ={

        "vrn" : this.vrn,
        "agent" :null,
        "client_name" : this.client_name,
        "client_number" :this.client_phonenumber,
        "quote_status" : "submitted",
        "zig_due" : null,
        "usd_due" : null,
        "amount" : this.result2,
        "vehicleValue" : null,
        "currency" : this.currency,
        "discountAmount" : null,
        "premiumAmount" : null,
        "insurancePeriod" : this.cover_period,
        "licencePeriod" : 0,
        "invoiceLineItemType":null,
        "insuranceType" : "COMPREHENSIVE",
        "vehicleUsage" : 1,
        "radioPeriod" : 0,
        "radioUsage" : 0,
        "promotionRate" :null,
        "exchangeRate" : null,
        "trackCompany":null,
        "insuranceCompany":this.company_type,
        "client_id" :this.client_idnumber,
        "package" : this.selected_package,
        "sum_insured" :this.sum_insured,
        "rate":this.rate_insured,
        "vehicle_type" :this.vehicle_type,
        "client_email" :this.client_email,
        "make_model" :this.make_model,
        "PolicyStart_date" :this.PolicyStart_date,
        "PolicyEnd_date" :this.PolicyEnd_date,
        "delivery_address" :this.delivery_address
      }
      fetch('https://verniabackend.vernia.co.zw/quotationsnew/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .then(response => {
          if (response.status === 201) {
            alert('Successfully saved the quote!');
            window.location.reload();  // This will refresh the page
          } else {
            alert('Failed to save the quote.');
          }
        })
        .catch(error => {
          console.error('Error:', error);
          alert('An error occurred. Please try again.');
        });

    },



    async ProcessQuote(){
      this.overlay = true;
      const payload ={

        "vrn" : this.vrn,
        "agent" :"Vernia1",
        "client_name" : this.client_name,
        "client_number" : this.client_phonenumber,
        "transaction_status" : "PAID",
        "amount" :this.result2,
        "currency" :this.currency,
        "coverPeriod" :this.cover_period,
        "insuranceType":"Comprehensive",
        "sum_insured" :this.sum_insured,
        "rate":this.rate_insured,
        "vehicle_type" :this.vehicle_type,
        "insuranceCompany" :this.company_type,
        "client_id" :this.client_idnumber,
        "payment_method" :this.currency,
        "reference_number" :this.rrn,
        "amount_paid" :this.amount_paid,
        "client_email" :this.client_email,
        "make_model" :this.make_model,
        "lead_source" :this.lead_source,
        "client_type" :this.client_type,
        "gender" :this.gender,
        "PolicyStart_date" :this.PolicyStart_date,
        "PolicyEnd_date" :this.PolicyEnd_date,
      "delivery_address" :this.delivery_address,
        "dob" :this.dob,
        "notes" :this.notes
      }
      const response = await axios.post('https://verniabackend.vernia.co.zw/transactions/', payload);
      if (response.status === 201) {
        this.overlay = false;
        alert('Successfully Processed!');
        window.location.reload();
      } else {
        // If status code is not 200, something went wrong
        alert('Warning: There was a problem Processing');
        this.overlay = false;
      }

    },

    async saveQuote() {
      console.log("saveQuote called");
      // Ensure responseData is valid
      if(this.responseData && this.responseData.body && this.responseData.body.invoiceLines) {
        // Extract the first invoice line object (this assumes there's only one)
        const invoiceLines = this.responseData.body.invoiceLines[0];
        // Form the request payload
        const payload = {

          "vrn": invoiceLines.vrn,
          "amount": invoiceLines.amount,
          "vehicleValue": invoiceLines.vehicleValue,
          "currency": invoiceLines.currency,
          "discountAmount": invoiceLines.discountAmount,
          "premiumAmount": invoiceLines.premiumAmount,
          "insurancePeriod": invoiceLines.insurancePeriod,
          "licencePeriod": invoiceLines.licencePeriod,
          "invoiceLineItemType": invoiceLines.invoiceLineItemType,
          "insuranceType": invoiceLines.insuranceType,
          "vehicleUsage": invoiceLines.vehicleUsage,
          "radioPeriod": invoiceLines.radioPeriod,
          "radioUsage": invoiceLines.radioUsage,
          "promotionRate": invoiceLines.promotionRate,
          "exchangeRate": invoiceLines.exchangeRate,
          "trackCompany": invoiceLines.trackCompany
        };

        // Post it to the server
        try {
          const response = await axios.post('https://verniabackend.vernia.co.zw/api/quotations/', payload, {
            headers: {
              'Authorization': `Bearer ${this.$store.getters.accessToken}`
            }
          });
          console.log(response.data);
          // On success, show a success alert
          if (response.status === 201) {
            alert('Successfully saved the quote!');
          } else {
            // If status code is not 200, something went wrong
            alert('Warning: There was a problem saving the quote');
          }
        } catch (error) {
          // On catch (network error, timeout etc.), show an error alert
          alert('An error occurred while saving the quote.');
        }
      }
    },




    async PaymentUpdate() {
      this.overlay = true;
      this.pay_dialog = false;
      this.EcocashWait = 7;
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const JWTToken = this.$store.getters.accessToken;
      axios
        .get(
          API +
            "/insurance/thirdparty/?payment_method=1&client_idnumber=" +
            this.client_idnumber +
            "&insurance_id=" +
            this.insurance_id +
            "&payment_status=1&client_mobile=&request_type=2",
          { headers: { Authorization: `Bearer ${JWTToken}` } }
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data);
            this.ViewTransaction();
            // this.responsedata = true
            // this.overlay = false
          } else {
            this.overlay = false;
            window.clearTimeout(this.timeoutID);
            this.$store.commit("clearUserData");
            alert("Your Session has ended");
            // this.$router.push("/login");
            window.location.href = "/login";
          }
        });
    },
    async ViewTransaction() {
      this.EcocashWait = 7;
      this.overlay = true;
      // await new Promise(resolve => setTimeout(resolve, 2000))
      // try{
      const JWTToken = this.$store.getters.accessToken;
      axios
        .get(
          API +
            "/insurance/thirdparty/?insuranceID=" +
            this.insurance_id +
            "&request_type=3",
          { headers: { Authorization: `Bearer ${JWTToken}` } }
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data);
            this.overlay = false;
            // response data
            this.paid_amount = res.data.Response.PremiumAmount;
            this.cover_period_approved = res.data.Response.DurationMonths;
            this.trans_status = res.data.Response.Status;
            this.approved_Vehiclevrn = res.data.Response.VRN;
            this.approved_Vehiclemake = res.data.Response.Make;
            this.approved_Vehiclemodel = res.data.Response.Model;
            this.approved_Vehicleyear = res.data.Response.YearManufacture;
            this.approved_by = res.data.Response.ApprovedBy;
            this.approved_date = res.data.Response.ApprovedDate;
            this.Town = res.data.Response.Town;
            this.policy_start_date = res.data.Response.StartDate;
            this.policy_end_date = res.data.Response.EndDate;
            this.EntityType = res.data.Response.EntityType;
            this.CompanyName = res.data.Response.CompanyName;
            this.StartDate = res.data.Response.StartDate;
            this.EndDate = res.data.Response.EndDate;
            this.Amount = res.data.Response.Amount;
            this.StampDuty = res.data.Response.StampDuty;
            this.GovernmentLevy = res.data.Response.GovernmentLevy;
            this.CoverAmount = res.data.Response.CoverAmount;
            this.PremiumAmount = res.data.Response.PremiumAmount;
            this.tax_class = res.data.Response.TaxClass;
            this.policy_number_icecash = res.data.Response.PolicyNo;
            this.retrieve_policyDialog = false;
            this.iceCashPatnerRef = res.data.PartnerReference;
            this.trans_summary = true;
          } else if (res.status === 202) {
            this.responsedata = true;
            this.overlay = false;
            this.quote_message = res.data.message.Response.Quotes[0].Message;
          } else {
            this.overlay = false;
            window.clearTimeout(this.timeoutID);
            this.$store.commit("clearUserData");
            alert("Your Session has ended");
            // this.$router.push("/login");
            window.location.href = "/login";
          }
        });
    },
    async SaveTransaction() {
      this.EcocashWait = 7;
      this.overlay = true;
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const JWTToken = this.$store.getters.accessToken;
      this.client_idnumber = this.client_idnumber.toUpperCase();
      this.client_name = this.client_name.toUpperCase();
      this.client_surname = this.client_surname.toUpperCase();
      this.client_email = this.client_email.toLowerCase();
      this.Address1 = this.Address1.toUpperCase();
      this.delivery_town = this.delivery_town.toUpperCase();
      this.policy_start_date = moment(Date()).format("YYYY-MM-DD");
      this.policy_end_date = moment(Date())
        .add(Math.round(30.5 * parseInt(this.cover_period_approved)), "days")
        .format("YYYY-MM-DD");

      const QuoteInfo = {
        vrn: this.approved_Vehiclevrn,
        id_number: this.client_idnumber,
        first_name: this.client_name,
        surname: this.client_surname,
        client_mobile: this.client_contactnumber,
        client_email: this.client_email,
        birth_date: "",
        currency: this.currency,
        address1: this.Address1,
        address2: this.Address2,
        town: this.client_town,
        zone: this.zone,
        entity_type: this.EntityType,
        company_name: "",
        policy_duration: this.cover_period_approved,
        policy_start_date: this.policy_start_date,
        policy_end_date: this.policy_end_date,
        amount: this.DurationPremium_amount,
        stamp_duty: this.DurationStamp_duty,
        government_levy: this.DurationGvt_levy,
        sum_insured: this.sum_insured,
        premium: this.DurationPremium_amount,
        insurance_type: "COMPREHENSIVE",
        vehicle_type: this.vehicle_type_comprehensive,
        vehicle_make: this.approved_Vehiclemake,
        vehicle_model: this.approved_Vehiclemodel,
        vehicle_year: this.approved_Vehicleyear,
        tax_class: this.tax_class,
        delivery_address1: this.delivery_address,
        delivery_address2: "",
        delivery_town: this.delivery_town,
        delivery: "",
        delivery_zone: this.zone,
        delivery_suburb: this.delivery_town,
        client_age: this.age,
        client_sex: this.selected_gender,
        business_source: this.business_source,
        invoice_file: "",
        receipt_file: "",
        policy_number_icecash: this.policy_number_icecash,
        policy_schedule_file: "",
        transaction_partner_ref: this.iceCashPatnerRef,
        issued_by: 1,
      };
      console.log(QuoteInfo);
      axios
        .request({
          url: "/transactions/add_insurance_transaction/",
          method: "post",
          baseURL: API,
          headers: {
            Authorization: `Bearer ${JWTToken}`,
            "Content-Type": "application/json",
          },
          data: QuoteInfo,
        })
        .then((res) => {
          if (res.status === 201) {
            console.log(res.data);
            console.log("delivery method", this.delivery_method);
            if (this.delivery_method === 1) {
              this.SaveDelivery();
            } else {
              this.retrieve_policyDialog = false;
              this.trans_summary = false;
              this.GetTransactions();
              this.clear();
            }
          } else if (res.status === 202) {
            this.responsedata = true;
            this.overlay = false;
          } else {
            this.overlay = false;
            window.clearTimeout(this.timeoutID);
            this.$store.commit("clearUserData");
            alert("Your Session has ended");
            // this.$router.push("/login");
            window.location.href = "/login";
          }
        });
    },
    async openDoc(val) {
      const url = API + val;
      window.open(url);
    },
    async SaveDelivery() {
      this.EcocashWait = 7;
      this.overlay = true;
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const JWTToken = this.$store.getters.accessToken;
      this.client_name = this.client_name.toUpperCase();
      this.client_surname = this.client_surname.toUpperCase();
      this.client_email = this.client_email.toLowerCase();
      this.Address1 = this.Address1.toUpperCase();
      this.delivery_town = this.delivery_town.toUpperCase();
      const DeliveryInfo = [
        {
          client_firstname: this.client_name,
          client_lastname: this.client_surname,
          client_contact: this.client_contactnumber,
          client_address1: this.Address1,
          client_address2: this.Address2,
          amount_owing: 0.0,
          client_signature: "",
          checked: false,
          zone: this.zone,
          surburb: this.delivery_town,
          call_center_response: "",
          driver: "",
        },
      ];
      console.log("Delivery", DeliveryInfo);
      axios
        .request({
          url: "/leads/deliveries/",
          method: "post",
          baseURL: API,
          headers: {
            Authorization: `Bearer ${JWTToken}`,
            "Content-Type": "application/json",
          },
          data: DeliveryInfo,
        })
        .then((res) => {
          if (res.status === 201) {
            console.log(res.data);
            this.overlay = false;
            this.retrieve_policyDialog = false;
            this.trans_summary = true;
            this.GetTransactions();
            this.clear();
          } else if (res.status === 202) {
            this.responsedata = true;
            this.overlay = false;
          } else {
            this.overlay = false;
            window.clearTimeout(this.timeoutID);
            this.$store.commit("clearUserData");
            alert("Your Session has ended");
            // this.$router.push("/login");
            window.location.href = "/login";
          }
        });
      await this.GetTransactions();
      this.clear();
    },
    async GetTransactions() {
      const JWTToken = this.$store.getters.accessToken;
      await axios
        .get(API + "/transactions/add_insurance_transaction/", {
          headers: { Authorization: `Bearer ${JWTToken}` },
        })
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            this.qoutes = res.data.filter((item) => {
              return item.insurance_type === "COMPREHENSIVE";
            });
            // this.qoutes = res.data
            this.overlay = false;
            this.clear();
          } else if (res.status === 401) {
            window.clearTimeout(this.timeoutID);
            this.$store.commit("clearUserData");
            alert("Your Session has ended");
            // this.$router.push("/login");
            window.location.href = "/login";
          } else {
            this.overlay = false;
            window.clearTimeout(this.timeoutID);
            this.$store.commit("clearUserData");
            alert("Your Session has ended");
            // this.$router.push("/login");
            window.location.href = "/login";
          }
        });
    },

    async cancelPolicy(id) {
      this.loading_dialog = true;

      if (this.$store.state.is_supervisor == false) {
        alert("You are not authorised to cancel policy😩");
        this.loading_dialog = false;
        this.clear();
      } else {
        const options = {
          method: "PATCH",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },

          body: JSON.stringify({
            id: id,
            policy_status: "CANCELLED",
          }),
        };

        fetch("http://196.43.100.211:5001/api/v1/cancellation/policy", options)
          .then((response) => {
            if (response.status == 200) {
              setTimeout(() => {
                this.loading_dialog = false;
                this.clear();
              }, 3000);
            } else {
              //  find a way to show errors
              // console.log("Error");
            }
          })
          .then((response) => console.log(response))
          .catch((err) => console.error(err));
      }
    },
    async ChangeSource() {
      if (
        this.business_source === "Referral from Existing Client" ||
        this.business_source === "Referral from Staff"
      ) {
        this.referalState = true;
      } else {
        this.referalState = false;
      }
      console.log(this.referalState);
    },
    clear() {
      this.selected_package = "";
      this.sum_insured = "";
      this.delivery_address = "";
      this.policy_number = "";
      this.policy_number_icecash = "";
      this.vehicle_type_comprehensive = "";
      this.vehicle_type_icecash = "";
      this.iceCashPatnerRef = "";
      this.insurance_type = "";
      this.DurationStamp_duty = "-----";
      this.DurationGvt_levy = "------";
      this.DurationCover_amount = "------";
      this.DurationPremium_amount = "------";
      this.QouteResult = 0;
      this.delivery_town_object = "";
      this.client_phonenumber = "";
      this.client_contactnumber = "";
      this.overlay = false;
      this.delivery = "";
      this.viewTransactionDetails = false;
      this.trans_status = "";
      this.payment_message = "Sending Payment Request...";
      this.radio = "";
      this.Address1 = "";
      this.Address2 = "";
      this.RadioType = "";
      this.LicenceID = "";
      this.frequency = "";
      this.payment_method = "";
      this.delivery_method = "";
      this.client_idnumber = "";
      this.vehicle_type = "";
      this.vehicle_use = "";
      this.vehicle_make = "null";
      this.vehicle_model = "null";
      this.yearOfmanufacture = "null";
      this.tax_class = "";
      this.quote_message = "";
      this.quote_type = "";
      this.search = "";
      this.client_name = "";
      this.client_surname = "";
      this.client_email = "";
      this.client_phonenumber = "";
      this.paid_amount = "";
      this.cover_period_approved = "";
      this.approved_policy_message = "";
      this.approved_trans_status = "";
      this.approved_Vehiclevrn = "";
      this.IDNumber = "";
      this.LoadedBy = "";
      this.IssuerCompany = "";
      this.Message = "";
      this.add = false;
      vehicle_use;
      this.show_quote = false;
      this.cover_period = "";
      this.trans_summary = false;
      this.LicenceID = "";
      this.quote_message = "";
      this.TotalTransAmount = "---";
      this.TransactionLicAmt = "---";
      this.PenaltiesAmt = "---";
      this.TotalLicAmt = "---";
      this.TotalRadioTVAmt = "---";
      this.RadioTVArrearsAmt = "---";
      this.TotalAmount = "---";
      this.vehicle_make = "null";
      this.vehicle_model = "null";
      this.yearOfmanufacture = "null";
      this.tax_class = "";
      this.vrn = "";
      this.vehicle_type = "";
      this.vehicle_use = "";
      this.first_name = "";
      this.last_name = "";
      this.email = "";
      this.user_id_number = "";
      this.phone_number = "";
      this.address = "";
      this.add = false;
    },
  },
};
</script>
<style>
.my-input input {
  text-transform: uppercase;
}
</style>
