import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { AxiosInstance as axios } from 'axios'
// import router from "../router"; // our vue router instance
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage:
      'https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-3.3a54f533.jpg',
    drawer: null,
    
    refresh_token: null,
    access_token: null,
   
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    setRefreshToken: function (state, refreshToken) {
      state.refresh_token = refreshToken
    },
    setAccessToken: function (state, accessToken) {
      state.access_token = accessToken
    },
    // sets state with user information and toggles
    // isAuthenticated from false to true
    
    clearUserData: function (state) {
      state.refresh_token = ''
      state.access_token = ''
      state.loggedInUser = ''
      state.isAuthenticated = false
      state.is_supervisor = false
      state.is_callagent = false
    },
  },
  actions: {
    refreshToken: async ({ state, commit }) => {
      const refreshUrl = 'refresh/'
      try {
        await axios
          .post(refreshUrl, { refresh: state.refresh_token })
          .then((response) => {
            if (response.status === 200) {
              commit('setAccessToken', response.data.access)
            }
          })
      } catch (e) {
        console.log(e.response)
      }
    },
    fetchUser: async ({ commit }) => {
      const currentUserUrl = ''
      try {
        await axios.get(currentUserUrl).then((response) => {
          if (response.status === 200) {
            commit('setLoggedInUser', response.data)
          }
        })
      } catch (e) {
        console.log(e.response)
      }
    },
  },
  getters: {
    
   
    accessToken: (state) => state.access_token,
    refreshToken: (state) => state.refresh_token,
   
  },
})
