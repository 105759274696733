<template>
  <div class="Policy_Schedule">
    <div class="Policy_Schedule-header">
      <h2>COMPREHENSIVE POLICY SCHEDULE</h2>
    </div>
    <div class="Policy_Schedule-body">
      <div>
        <h3>Details</h3>
        <p>VRN: {{ item.vrn }}</p>
        <p>AGENT: {{ item.agent }}</p>
        <p>CLIENT NAME: {{ item.client_name }}</p>
        <p>CLIENT NUMBER: {{ item.client_number }}</p>
        <p>INSURANCE COMPANY: {{ item.insuranceCompany }}</p>
        <p>CURRENCY: {{ item.currency }}</p>
        <p>Amount DUE: {{ item.amount }} {{ item.currency }}</p>
        <p>AMOUNT PAID: {{ item.amount_paid }}</p>
        <p>TRANSACTION DATE: {{ item.createdDate }}</p>
        <!-- Include other row details as necessary -->
      </div>
    </div>
    <div class="Policy_Schedule-footer">
      <p>Thank you for choosing us VERNIA MULTIPLE AGENT!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Policy_Schedule',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.Policy_Schedule {
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: 0 auto;
}

.Policy_Schedule-header, .Policy_Schedule-footer {
  background-color: #2cf6f6;
  padding: 30px;
}

.Policy_Schedule-body {
  padding: 20px;
}
</style>

