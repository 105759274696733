<template>
  <div class="invoice">
      <div class="invoice-box">
        <table cellpadding="0" cellspacing="0">
          <tr class="top">
            <td colspan="12">
              <table>
                <tr>
                  <td class="title">
                    <img src="@/assets/logo.png" style="width:100%; max-width:300px;">
                  </td>

                  <td>
                    Invoice #: {{ item.id }}<br> Created: {{item.createdDate}}<br>
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr class="information">
            <td colspan="12">
              <table>
                <tr>
                  <td>
                    SUIT 311<br> ECO BANK BUILDING<br> NELSON MANDELA AVENUE, HARARE
                  </td>

                  <td>
                    {{ item.client_name }}<br> {{ item.client_number }}<br> {{ item.client_email }}<br>{{ item.delivery_address }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr class="heading">
            <td colspan="1">Policy ID</td>
            <td colspan="2">Start Date</td>
            <td colspan="3">End Date</td>
          </tr>

          <tr class="details">
            <td colspan="1">VMA{{ item.id }}</td>
            <td colspan="2">{{item.PolicyStart_date}}</td>
            <td colspan="3">{{item.PolicyEnd_date}}</td>
          </tr>

          <tr class="heading">
            <td>RegNo.</td>
            <td>MAKE/MODEL</td>
            <td>Sum Insured/Value</td>
            <td></td>
            <td></td>
            <td>Selected Cover</td>
          </tr>

          <tr >
            <td>{{ item.vrn }}</td>
            <td></td>
            <td>{{ item.sum_insured }}</td>
            <td></td>
            <td></td>
            <td>{{ item.amount }}</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>

          <tr>
            <td>Government Levy</td>
            <td></td>
            <td>USD$ 10.80</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Stamp Duty</td>
            <td></td>
            <td>5%</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr class="heading">
            <td>TOTAL</td>
            <td></td>
            <td></td>
            <td>{{ item.result }}</td>
            <td>{{ item.result3 }}</td>
            <td class="total">{{ item.amount }}</td>
          </tr>

          <tr class="total">
            <td colspan="3"></td>
            <td>Total: ${{ item.amount }}</td>
          </tr>
        </table>

      </div>
    <div class="invoice-footer">
      <h2>Motor Excesses</h2>
      <table>
      <tr>
        <td>Partial Loss </td>
        <td>10% of loss</td>
      </tr>
        <tr>
          <td>Partial Loss out of Zimbabwe </td>
          <td>5% of Sum Insured</td>
        </tr>
        <tr>
          <td>Total Loss</td>
          <td>3% of Sum Insured</td>
        </tr>
        <tr>
          <td>Windscreen </td>
          <td>33.33% contribution</td>
        </tr>
        <tr>
          <td>Total Loss out of Zimbabwe </td>
          <td>5% of Sum Insured</td>
        </tr>
        <tr>
          <td>Theft of Accessories(car radio,tyre etc) </td>
          <td>25% of loss</td>
        </tr>
        <tr>
          <td>Undeclared Audio Equipment Limt </td>
          <td>5% of Sum insured min $500</td>
        </tr>
        <tr>
          <td>Drivers below 25 years </td>
          <td>3% of Sum Insured</td>
        </tr>
        <tr>
          <td>Driver's license below 5 years </td>
          <td>3% of Sum Insured</td>
        </tr>
        <tr>
          <td>Driver with learner's license </td>
          <td>5% of Sum Insured</td>
        </tr>
        <tr>
          <td>Driver not on the schedule </td>
          <td>2% of Sum Insured</td>
        </tr>
      </table>
      <h2>Third Party Limits</h2>
      <p>Death and Bodily Injury   USD 10,000.00</p>
      <p>Property Damage           USD 10,000.00</p>
    </div>


  </div>
</template>

<script>
export default {
  name: 'Invoice',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.invoice {
  max-width: 700px;
  margin: auto;
  padding: 1px;
  border: 1px solid #ffffff;

}
.invoice-footer {
  font-size: 11px;
  line-height: 10px;
}
.invoice-box {
  max-width: 700px;
  margin: 1px;
  padding: 30px;
  border: 1px solid #ffffff;
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(n + 2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #0e31b4;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #0e31b4;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.item input {
  padding-left: 5px;
}

.invoice-box table tr.item td:first-child input {
  margin-left: -5px;
  width: 100%;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

.invoice-box input[type="number"] {
  width: 60px;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
  sans-serif;
}

.rtl table {
  text-align: right;
}

.rtl table tr td:nth-child(2) {
  text-align: left;
}


</style>
