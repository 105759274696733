import Vue from "vue";
import Router from "vue-router";

import Login from "../src/views/Login";

import Dashboard from "../src/views/dashboard/pages/Dashboard";
import DashboardIndex from "../src/views/dashboard/Index";
import Comprehensive from "./views/dashboard/pages/Comprehensive";
import Quotations from "./views/dashboard/pages/Quotations";
import Transactions from "./views/dashboard/pages/Transactions";
import Git from "./views/dashboard/pages/Git";




Vue.use(Router);
const routes = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/login",
    component: Login,
  },

  {
    path: "/user",
    component: DashboardIndex,
    children: [
      {
        path: "dashboard",
        component: Dashboard,
      },

      {
        path: "Comprehensive",
        component: Comprehensive,
      },
      {
        path: "Git",
        component: Git,
      },
      {
        path: "Quotations",
        component: Quotations,
      },
      {
        path: "Transactions",
        component: Transactions,
      },
    ],
  },
];
const router = new Router({
  routes,
  mode: "hash",
});

export default router;
