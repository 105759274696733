<template>
  <div class="pt-5">
    <div id="login-page">

      <br />
      <div
        id="container"
        :class="SignUpState ? 'container1 right-panel-active' : 'container1'"
      >
        <div>

          <div class="form-container sign-in-container">
            <form action="#">
              <h1>Sign in</h1>
              <div class="social-container">
                <a href="#" class="social"><img src="../assets/log.png" alt="vernia" height="40"></a>
                <a href="#" class="social"><img src="../assets/3.png" alt="moovah" height="50"></a>
                <a href="#" class="social"><img src="../assets/4.jpg" alt="fbc" height="40"></a>
                <a href="#" class="social"><img src="../assets/2.png" alt="econet" height="40"></a>
              </div>

              <br />
              <input
                v-model="loginModel.username"
                style="text-transform: lowercase"
                class="input_class"
                type="text"
                placeholder="username"
                autocomplete="off"
                required
              />
              <input
                v-model="loginModel.password"
                class="input_class"
                type="password"
                placeholder="password"
                autocomplete="off"
                required
              />
              <button class="button_class" @click="login">Sign In</button>
            </form>
          </div>
        </div>
        <div class="overlay-container" color="success">
          <div class="overlay" color="success">
            <div>

              <div class="overlay-panel overlay-right" color="success">
                <h1>{{ welcome_message }}!</h1>
                <p>Please sign-in  to get started</p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
// import {setCookie} from "@/util/cookie_helper";
export default {
  name: "Login",
  computed: {

  },
  data() {
    return {
      loginModel: {
        username: "",
        password: ""
      },

      username: "",
      password: null,

    };
  },
  beforeMount() {
    const d = new Date();
    const hour = d.getHours();
    // if else check
    if (hour < 11) {
      this.welcome_message = "Good Morning";
    } else if (hour > 11 && hour <= 15) {
      this.welcome_message = "Good Afternoon";
    } else if (hour > 15 && hour <= 20) {
      this.welcome_message = "Good Day";
    } else {
      this.welcome_message = "Good Day";
    }
  },
  methods: {
    login() {
      if (this.loginModel.username === "admin" && this.loginModel.password === "password") {
        //this person is admin
        // setCookie("user_role","admin",10);
        this.$router.push("/user/dashboard");
      } else if (this.loginModel.username === "user" && this.loginModel.password === "password") {
        // setCookie("user_role","user",10);
        this.$router.push("/user/dashboard");
      }else{
        //mmmm these are some wrong credentials don't you think?
      }
    },

    // async login(){
    //   window.location="/user/dashboard"
    // },

    complete(index) {
      this.list[index] = !this.list[index];
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");
#login-page {
  box-sizing: border-box;
  background-image:url('../assets/login.jpg');
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  height: 100vh;
  margin: -20px 0 50px;
}

h1 {
  font-weight: bold;
  margin: 0;
}

h2 {
  text-align: center;
}

p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

span {
  font-size: 12px;
}

a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.button_class {
  /* border-radius: 20px; */
  border: 1px solid #34b3ea;
  background-color: #8f27e0;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

button:active {
  transform: scale(0.95);
}

button:focus {
  outline: none;
}

button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

.input_class {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.container1 {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container1.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.container1.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container1.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: #0c8029;
  background: -webkit-linear-gradient(to right, #0c8029, #0c8029);
  background: linear-gradient(to right, #0c8029, rgb(12, 128, 41));
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container1.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.container1.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container1.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

footer {
  background-color: #222;
  color: #fff;
  font-size: 14px;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
}

footer p {
  margin: 10px 0;
}

footer i {
  color: red;
}

footer a {
  color: #3c97bf;
  text-decoration: none;
}
</style>
